jQuery(document).ready(function () {
  let container = jQuery(document).width();

  if (container >= 1280) {
    jQuery('#page-content, .menu-item:not(.has-mega-menu)').mouseenter(function (e) {
      megaMenuClean();
    });
    jQuery('.has-mega-menu').mouseenter(function (e) {
      e.preventDefault();

      megaMenuClean();

      let body = jQuery('body');

      let menu_id = jQuery(this).attr('id');
      if (jQuery(this).hasClass('mega-menu-open')) {
        jQuery(this).removeClass('mega-menu-open');
      } else {
        jQuery(this).addClass('mega-menu-open');

        let mega_block = jQuery('#mega-' + menu_id);
        let menu_position = jQuery(this).position();
        let menu_width = jQuery(this).width();
        let position_left = menu_position.left + (menu_width / 2) - 30;
        mega_block.find('.mega-menu__before').css('left', position_left + 'px');
        mega_block.stop().fadeIn();
      }

      if (body.hasClass('mega-menu-active')) {
        body.removeClass('mega-menu-active');
      } else {
        body.addClass('mega-menu-active');
      }

      return false;
    });
  }
});

function megaMenuClean() {
  jQuery('.mega-menu').stop().fadeOut();
  jQuery('body').removeClass('mega-menu-active');
  jQuery('.menu-item').removeClass('mega-menu-open');
}

document.addEventListener(
  "click",
  function (event) {
    if (!event.target.closest(".mega-menu")) {
      jQuery('.mega-menu').stop().fadeOut();
      jQuery('body').removeClass('mega-menu-active');
      jQuery('.menu-item').removeClass('mega-menu-open');
    }

    if (!event.target.closest(".modal-window") && !event.target.closest(".trigger")) {
      jQuery('.close').click();
    }
  },
  false
);